import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/layout/Navbar'
import Footer from './components/layout/Footer';
import Home from './pages/Home'
import About from './pages/About';
import NotFound from './pages/NotFound';
import Alert from './components/layout/Alert';
import Landing from './Landing';
import Login from './components/Authentication/Login';
import Signout from './components/Authentication/Signout';
import Verify from './components/Authentication/Verify';
import UserDashboard from './pages/users/Dashboard/UserDashboard';
import User from './pages/users/User'
import './App.css';
import { AlertProvider } from './context/alerts/AlertContext';
import { UserProvider } from './context/users/UserContext';
import AuthProvider from './hooks/auth/AuthProvider';
import PrivateRoute from './routers/PrivateRoute';

function App() {
  return (
    <Router>
      
      <UserProvider>
      <AlertProvider>
      <AuthProvider>
      <div className="macbook-pro">
        <div className="div">
            <Alert />
            <NavBar />
            <Routes>
              <Route path='/' element={<Landing/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='/dashboard' element={<UserDashboard/>} />
              <Route path='/profile' element={<User/>} />
              <Route element= {<PrivateRoute/>}>
                <Route path='/logout' element={<Signout/>} />
                <Route path='verify' element={<Verify/>} />
                
              </Route>
            </Routes>
        </div>
      </div>
      </AuthProvider>
      </AlertProvider>
      </UserProvider>
      
      
    </Router>
  );
}

export default App;

