import { createContext, useReducer } from "react";
import userReducer from "./UserReducer"


const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const initialState = {
    user: {},
    userEditted: false
  }

  const [state, dispatch] = useReducer(userReducer, initialState)

  const setUser = (user, userEditted= false) => {
    console.log('In UserContext setting user ', user)
    dispatch({
        type: 'SET_USER',
        payload: user
      })
      
  }

  const setUserDetails = (userDetails) => {
    console.log('In UserContext setting user details', userDetails)
    dispatch({
        type: 'SET_USER_DETAILS',
        payload: userDetails
      })
      
  }

  const setUserEdit = (userDetails) => {
    dispatch({
      type: 'SET_USER_EDIT',
      payload: userDetails
    })
  }

  const resetUserEdit = (userEditted) => {
    dispatch({
      type: 'RESET_USER_EDIT',
      payload: userEditted
    })
  }


  return (<UserContext.Provider value={{
   ...state,
   userEditted: state.userEditted,
   setUser,
   setUserEdit,
   resetUserEdit,
   setUserDetails,
    dispatch
  }}>
    {children}
  </UserContext.Provider>
  )  
}
export default UserContext 