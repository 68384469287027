
import { CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
    UserPoolId: 'us-east-2_PqRIJrMpp',
    ClientId: '149o8tsk5ead41p4trb1s6i8ch'
}

var userPool = new CognitoUserPool(poolData)


export default userPool;