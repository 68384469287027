import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TEChart }  from "tw-elements-react";
import AlertContext from '../../../context/alerts/AlertContext'
import UserContext from '../../../context/users/UserContext'
import {useState, useContext} from 'react'



function DashboardHome() {
  const {user, setUser, dispatch} = useContext(UserContext)
  const [userDetails, setUserDetails] = useState('')
  const navigate = useNavigate()
  const userId = user?.userId;
  console.log('In Home ', userId)
  return (
    <div>
    {userId && (
      <div>
      <div className="shadow-lg w-full mx-auto overflow-hidden">
      <div className="text-lg py-3 px-5 bg-gray-50">
        Rental Trend
        <TEChart
          type="line"
          data={{
            labels: ['January', 'February' , 'March' , 'April' , 'May' , 'June' , 'July','August', 'September','October', 'November', 'December'],
            datasets: [
              {
                label: 'Your Property',
                data: [2500, 2500, 2500, 2500, 1200, 1200, 900, 3000, 3000, 3000, 3000, 3000]
              },
              {
                label: 'Avg By Zipcode',
                data: [4000, 4500, 4500, 5000, 2000, 2000, 1000, 5000, 5000, 4000, 4000, 4000],
                borderColor: 'black',
                pointBackgroundColor: 'black'
                
              },
            ]
          }}
          options={{
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || "";
                    label = `${label}: $${context.formattedValue}`;
                    return label;
                  },
                },
              },
            },
          }}
       />
      </div>
      </div>
      </div>
    )}
    </div>

    


  )
}

export default DashboardHome