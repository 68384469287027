import React from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../context/users/UserContext'
import {useState, useContext} from 'react'

function Signout() {
  const navigate = useNavigate()
  const {user, setUser, dispatch} = useContext(UserContext)

  const handleLogin = (e) => {
    console.log('Clicked login ')
    navigate('/login')
    
  }

  return (
    <div className='flex h-screen justify-center m-auto'>
       
              <div className='overlap-6 grid flex flex-col justify-center items-center rounded form-control my-10 mx-10'>
                <div>
                  <h1 className="text-4xl font-sans text-center text-white font-bold">You are logged out now</h1>
                </div>
                <div className='ml-10 mr-10 form-control'>
                  
                  <div className="form-control ml-10 mr-10">
                    <button className="btn-sm rounded-full btn-neutral bg-black-800 font-semibold" onClick={handleLogin}>Log In</button>
                  </div>
                
                </div>
              </div>
          
      </div>
  )
}

export default Signout