import React from 'react'
import { useContext } from 'react'
import Signup from './components/Authentication/Signup'
import Login from './components/Authentication/Login'
import UserContext from './context/users/UserContext'


function Landing() {
  const { User } = useContext(UserContext)
  return (
      <>
          <div className="overlap-group">
            
            <div className="overlap-2">
              <div className="rectangle"></div>
              <div className="group">
                <div className="overlap-group-2">
                  <div className="text-wrapper-5">PROPPTYFY</div>
                  <div className="text-wrapper-6">Property Management Simplified</div>
                </div>
              </div>
              <img className="ellipse" src="https://c.animaapp.com/0K95c8Xq/img/ellipse-2.svg" />
              <div className="ellipse-2"></div>
              <div className="overlap-wrapper">
                <div className="overlap-3">
                  <div className="LOGO">
                    <div className="overlap-3">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="rectangle-2"></div>
                          <img className="polygon" src="https://c.animaapp.com/0K95c8Xq/img/polygon-2.svg" />
                          <div className="rectangle-3"></div>
                        </div>
                      </div>
                      <div className="div-wrapper">
                        <div className="overlap-4">
                          <img className="img" src="https://c.animaapp.com/0K95c8Xq/img/polygon-1.svg" />
                          <div className="rectangle-4"></div>
                        </div>
                      </div>
                      <div className="rectangle-5"></div>
                      <div className="rectangle-6"></div>
                      <div className="rectangle-7"></div>
                      <div className="rectangle-8"></div>
                      <div className="rectangle-9"></div>
                      <div className="rectangle-10"></div>
                      <div className="rectangle-11"></div>
                      <div className="rectangle-12"></div>
                      <div className="rectangle-13"></div>
                      <div className="rectangle-14"></div>
                      <div className="rectangle-15"></div>
                      <div className="rectangle-16"></div>
                      <div className="rectangle-17"></div>
                    </div>
                  </div>
                  <img className="group-2" src="https://c.animaapp.com/0K95c8Xq/img/group-6@2x.png" />
                  <img className="group-3" src="https://c.animaapp.com/0K95c8Xq/img/group-7@2x.png" />
                  <img className="line" src="https://c.animaapp.com/0K95c8Xq/img/line-4.svg" />
                  <img className="line-2" src="https://c.animaapp.com/0K95c8Xq/img/line-5.svg" />
                  <img className="line-3" src="https://c.animaapp.com/0K95c8Xq/img/line-6.svg" />
                  <img className="line-4" src="https://c.animaapp.com/0K95c8Xq/img/line-7.svg" />
                  <img className="line-5" src="https://c.animaapp.com/0K95c8Xq/img/line-8.svg" />
                  <img className="line-6" src="https://c.animaapp.com/0K95c8Xq/img/line-9.svg" />
                  <img className="line-7" src="https://c.animaapp.com/0K95c8Xq/img/line-10.svg" />
                  <img className="line-8" src="https://c.animaapp.com/0K95c8Xq/img/line-11.svg" />
                  <img className="line-9" src="https://c.animaapp.com/0K95c8Xq/img/line-12.svg" />
                </div>
              </div>
              <div className="rectangle-18"></div>
              <img className="untitled-design" src="https://c.animaapp.com/0K95c8Xq/img/untitled-design--6--1@2x.png" />
              <p className="property-management">
                <span className="span">Property Management<br /></span>
                <span className="text-wrapper-7"
                  >Essential tools for property management, emphasizing move in and move out processes<br
                /></span>
              </p>
              <p className="tenants-accessible">
                <span className="span">Tenants<br /></span>
                <span className="text-wrapper-7"
                  >Accessible features for swift communication, quick services, and rental portal<br
                /></span>
              </p>
            </div>
            <div className="overlap-5">
              {User?.userId && (
                <Login />
              )}
              {!User?.userId && (
                <Signup />
              )}
                  
                  
                  
              
              </div>
            </div>
          </>
    


  )
}

export default Landing


