import React from 'react'
import { useNavigate } from 'react-router-dom'
import UserPool from '../../UserPool'
import AlertContext from '../../context/alerts/AlertContext'
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'
import UserContext from '../../context/users/UserContext'
import {useState, useContext} from 'react'
import { saveUser } from '../../context/users/UserActions'
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from 'react-hook-form'
import { useAuth } from '../../hooks/auth/AuthProvider'



function Signup() {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState();
  const [disabled, setDisabled] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [agreeToAllTerms, setAgreeToAllTerms] = useState(false);
  const {setAlert} = useContext(AlertContext)
  const { setUser } = useContext(UserContext)
  
  const navigate = useNavigate()
  const auth = useAuth()

  const schema = yup.object().shape({
    email: yup.string().email().required().label('Email'),
    password: yup.string()
      .required('No password provided.') 
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .label('Password')
    })

  const { register, handleSubmit, formState: { errors }, reset} = useForm({resolver: yupResolver(schema)})

  let authDetails = new AuthenticationDetails({
    Username: email,
    Password: password
  })
  
  //onclick handler when clicking a menu item
  const handleSelection = (e) => {
    setOpen(false);
    setSelectedRole(e.target.value)
  };

  const handleChangeChk = (chkValue) => {
    setAgreeToAllTerms(chkValue.target.checked);
    console.log(chkValue.target.checked)
    if (chkValue.target.checked === true) {
      setDisabled('')
    } else {
      setDisabled('disabled')
    }
  };

  const clearEntries = () => {
    setPassword('')
    setEmail('')
  }

  const onSubmitHandler = (e) => {
    console.log('In Submit selectedRole ', selectedRole)
    if (selectedRole === undefined) {
      setAlert('You have to select a role', 'error') 
    }  else {
        setAlert('', 'REMOVE_ALERT')
        return new Promise((resolve, reject) =>
          UserPool.signUp(email, password, [], null, (err, result) => {
            console.log(err)
            if (err) {
              setAlert('Error Creating User!! ' + err.message || JSON.stringify(err), 'error')
              console.log(err.message || JSON.stringify(err))
              clearEntries()
              
            } else {
              resolve(result);
              console.log(result)
              setAlert('Registration successful. Confirm your registration by clicking on the link in the email we sent you', 'success')
              if (result?.userSub) {
                const userData = {userId: result.userSub, email, role: selectedRole}
                auth.signupAction(userData)
                
                saveUser(userData, 'INSERT')
                clearEntries()
                setTimeout(() => navigate('/login'), 3000)
              }
              /*userData.token = user.token
              saveUser(userData, 'INSERT')
              setUser(userData)
              setAlert('User successfully created', 'success')*/
            
              
            }
          })
      );
    }
  }

  const handlePassword = (e) => setPassword(e.target.value)

  const handleEmail = (e) => setEmail(e.target.value)

  const enabledStyle = "btn-sm rounded-full btn-neutral bg-black-800 font-semibold"
  
  const disabledStyle = 'btn-sm rounded-full btn-neutral bg-black-800 font-semibold opacity-50 cursor-not-allowed'
  
  return (
    <div>
      <div className="overlap-group">
        <div className="overlap-6">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className='grid grid-rows-6 mb-8 flex justify-start form-control'>
       
              <div>
                <h1 className="text-4xl mb-4 text-black font-sans text-center py-4 text-white font-bold">Get Started</h1>
              </div>
              <div className='ml-10 mr-10 form-control'>
                <input 
                  type="email"
                  {...register("email")}
                  value={email}
                  placeholder='Email'
                  onChange={handleEmail}
                  className='w-full pr-10 bg-gray-200 input input-md text-black py-4'/>
                <p className="mt-2 text-red-700 text-xs italic md:font-bold">{errors.email?.message}</p>
              </div>
              <div className='ml-10 mr-10 form-control'>
                <input 
                  type="password"
                  {...register("password")}
                  value={password}
                  placeholder='Password'
                  onChange={handlePassword}
                  className='w-full pr-10 bg-gray-200 input input-md text-black py-4s'/>
                <p className="mt-2 mb-2 text-red-700 text-xs italic md:font-bold">{errors.password?.message}</p>
              </div>
              <div className='form-control'>
                <select value={selectedRole} className="select w-full ml-10 mr-10 max-w-xs text-black" onChange={handleSelection}>
                  <option value={""}>I want to access as a</option>
                  <option value={"manager"}>Property Manager</option>
                  <option value={"tenant"}>Tenant</option>
                  <option value={"admin"}>Admin</option>
                </select>
              </div>
              <div className="form-control ml-10">
                <label className="label cursor-pointer">
                  <input type="checkbox" checked={agreeToAllTerms} onChange={(event)=> {handleChangeChk(event)}} className="checkbox-md mr-2" />
                  <span className="label-text text-white whitespace-normal">I have read and agree to Terms of Use and Privacy Policy</span> 
                </label>
              </div>
              <div className="form-control ml-10 mr-10">
                <button type="button" disabled = {disabled} className= {agreeToAllTerms? enabledStyle : disabledStyle} onClick={handleSubmit(onSubmitHandler)}>Create Account</button>
              </div>
              <div className='form-control ml-10 mr-10'>
                <p className="already-have-an">
                    <span className="text-wrapper-13">Already have an account? </span>
                    <span className="text-wrapper-14"><a href="/login">Sign In</a></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )

}
export default Signup


