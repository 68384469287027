
import axios from 'axios'
const USER_SERVICE_URL = process.env.REACT_APP_USER_LAMBDA_URL


export const saveUser = async(userData, action= 'UPDATE') => {

  axios.defaults.baseURL= `https://${USER_SERVICE_URL}/`
  //axios.defaults.baseURL = 'http://localhost:4000/';
  try {
    const headers = {
      'Content-Type': 'application/json'
    }
    console.log('In saveUser ', userData)
    
    const response = await axios.post(`createUser?action=${action}`, 
      userData, {
        headers: headers
      });
    return response 
  } catch (error) {
    console.log('Error while saving user ', error)
    throw error
  } 
}

//Find User based on string
export const fetchUserDetails = async({user}) => {
    console.log('In fetchUserDetails userId', user?.userId)
    const userId = user?.userId
    const authToken = localStorage.getItem('token')
    axios.defaults.baseURL = `https://${USER_SERVICE_URL}/`;
    //axios.defaults.baseURL = `http://localhost:4000/`
    console.log('Got userId ', userId)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+authToken
      }
      
      const response = await axios.get(`users/${userId}`, 
        {
          headers: headers
        });
        console.log('In fetchUserDetails response ', response)
        return response?.data
    } catch (error) {
      throw error
    } 
    
}

