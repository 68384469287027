import React from 'react'
import { useEffect, useContext, useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import UserContext from '../../context/users/UserContext'
import AlertContext from '../../context/alerts/AlertContext'
import { saveUser } from '../../context/users/UserActions'
import { fetchUserDetails } from '../../context/users/UserActions'

function User() {
  const {user, setUser, dispatch} = useContext(UserContext)
  const [edit, setEdit] = useState('')
  const {setAlert} = useContext(AlertContext)
  const [selectedState, setSelectedState] = useState('')
  const navigate = useNavigate()
  //const login = params.login
  useEffect(() => {
    const getUser = async (user) => {
      console.log('#########In getUser', user?.userId)
      try{
        if(user){
          const userData = await fetchUserDetails({user})
          console.log('Got user details ', userData)
          dispatch({type: 'GET_USER', payload: userData})
        }
      } catch(error) {
        dispatch({
          type: 'USER_ERROR',
          error,
        })
      }
    }
    getUser(user)
  },[])

  
  const firstName = user?.firstName
  const  lastName = user?.lastName
  const address1 = user?.address1
  const address2 = user?.address2
  const city = user?.city
  const state = user?.state
  const zip = user?.zip
  const email = user?.email
  const phone = user?.phone
  const mobile = user?.mobile

  const schema = yup.object().shape({
    email: yup.string().email().required().label('Email'),
    firstName: yup.string().min(2).max(20).required().label('First Name'),
    lastName: yup.string().min(2).max(20).required().label('Last Name'),
    phone: yup.string().optional().label('Phone'),
    mobile: yup.string().required().label('Mobile'),
    address1: yup.string().optional(),
    address2: yup.string().optional(),
    city: yup.string().optional(),
    zip: yup.string().optional(),
  })

  const { register, handleSubmit, formState: { errors }, reset} = useForm({resolver: yupResolver(schema), mode: "onSubmit"})

  const handleEdit = (e) => {
    console.log('Edited ', edit)
    setEdit(!edit)
  }

  const onSubmit = async(userData) => {
    console.log('In OnSubmitHandler')
    userData.userId = user.userId
    userData.role = user.role
    userData.state = selectedState
    try {
      if (saveUser(userData)) {
        setAlert('Profile Updated Successfully! ', 'success')
        setUser(userData)
        setEdit(!edit)
        dispatch({type: 'RESET_USER_EDIT', payload: userData})
      }
    } catch(error) {
      dispatch({type: 'USER_ERROR'})
    }
    //clearEntries()
    
  }

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center rounded form-control">
        <form className="rounded-lg bg-gray-500 rounded h-300 w-full mt-6"  onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap mt-6 mb-6 px-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
             <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                First Name
              </label>
              {!edit &&
              <div className="flex flex-wrap text-white mb-6">
                <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
                  {firstName}
                </div>
              </div>
              }
              {edit &&
              <div>
                <input 
                className="appearance-none block w-3/4 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" 
                type="text" 
                {...register("firstName", {
                  required: "First Name needs to be atleast 2 characters"
                })}
                placeholder="First Name"
                defaultValue={firstName}>
              </input>
              <p className="text-red-500 text-xs italic">{errors.userFirstName?.message}</p>
              </div>
              }
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                Last Name
              </label>
              {edit === false &&
              <div className="flex flex-wrap text-white mb-6">
                <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
                  {lastName}
                </div>
              </div>
              }
              {edit === true &&
                  <input 
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                    id="grid-last-name" 
                    type="text"
                    {...register("lastName")}
                    placeholder="Last Name"
                    defaultValue={lastName}>
                  </input>
              }
            </div>
        </div>
        <div className="flex flex-wrap mt-6 mb-6 px-4">   
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="address1">
                Address Line 1
              </label>
              {!edit && 
                <div className="flex flex-wrap text-white mb-6">
                  <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
                    {address1}
                  </div>
                </div>
              }
              {edit && 
                <input 
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                  id="grid-address1" 
                  type="text"
                  {...register("address1")} 
                  defaultValue={address1}
                  placeholder="Address 1">
                </input>
                
              }
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="address2">
                Address Line 2
              </label>
              {!edit && 
                <div className="flex flex-wrap text-white mb-6">
                  <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
                    {address2}
                  </div>
                </div>
              }
              {edit && 
                <input 
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                  id="grid-address2" 
                  type="text"
                  {...register("address2")} 
                  defaultValue={address2}
                  placeholder="Address 1">
                </input>  
              }
            </div>
        </div>
        <div className="flex flex-wrap mt-6 mb-6 px-4">
          <div className="md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="city">
                City
            </label>
            {!edit && 
              <div className="flex flex-wrap text-white mb-6">
                <div className="md:w-1/2 pr-3 mb-6 md:mb-0">
                  {city}
                </div>
              </div>
            }
            {edit && 
              <input 
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                id="grid-city" 
                type="text"
                {...register("city")}
                defaultValue={city}
                placeholder="City">
              </input>
            }
          </div>
          <div className="md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="state">
                State
            </label>
            {!edit && 
              <div className="flex flex-wrap text-white mb-6">
                <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
                  {state}
                </div>
              </div>
            }
            {edit && 
              <div className="relative">
              <select 
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-400 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                id="grid-state" defaultValue={state}
                onChange={(e) => {e.preventDefault();setSelectedState(e.target.value)}} >
                <option className="w-full bg-gray-200 text-gray-700"
                >State</option>
                <option selected={state === 'CA'} value="CA">California</option>
                <option selected={state === 'NM'} value="NM">New Mexico</option>
                <option selected={state === 'MS'} value="MS">Missouri</option>
                <option selected={state === 'TX'} value="TX">Texas</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
            }
          </div>
          <div className="md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="zip">
                Zip
            </label>
            {!edit &&
              <div className="flex flex-wrap text-white mb-6">
                <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
                  {zip}
                </div>
              </div>
            }
            {edit &&
              <input 
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
              id="grid-zip" 
              type="text"
              {...register("zip")}
              placeholder="Zip"
              defaultValue={zip}>
            </input>
            }    
          </div>
      </div> 
      <div className="flex flex-wrap mt-6 mb-6 px-4">
      <div className="md:w-1/3 px-3 mb-6 md:mb-0">
        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
        </label>
        
        <div className="flex flex-wrap text-white mb-6">
          {!edit && 
            <div className="md:w-1/2 pr-3 mb-6 md:mb-0">
              {email}
            </div>
          }
          {edit &&
            <input 
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
            id="grid-last-name" 
            type="text"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Email is invalid"
              }
            })} 
            placeholder="Email"
            defaultValue={email}>
          </input>
          }
        </div>
      </div>
      <div className="md:w-1/3 px-3 mb-6 md:mb-0">
        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
            Mobile
        </label>
        <div className="flex flex-wrap text-white mb-6">
          {!edit && 
          <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
            {mobile}
          </div>
          }
          {edit && 
            <input 
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" 
            type="text" 
            {...register("mobile", {
              required: "Mobile needs to be 10 digits"
            })}
            placeholder="Mobile"
            defaultValue={mobile}>
          </input>
          }
        </div>
      </div>
      <div className="md:w-1/3 px-3 mb-6 md:mb-0">
        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Phone
        </label>
        <div className="flex flex-wrap text-white mb-6">
          {!edit &&
            <div className="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
              {phone}
            </div>
          }
          {
            edit &&
            <input 
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" 
              type="text" 
              {...register("phone")}
              placeholder="Phone"
              defaultValue={phone}>
            </input>
          }
        </div>
      </div>

    </div>
    
      <div className="flex justify-left mb-6 ml-6">
        {!edit && 
          <button className="bg-gray-300 hover:bg-gray-400 text-white-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={handleEdit}>
            <svg className="w-6 h-6 text-gray-800 dark:text-white fill-current w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
              <path d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z"/>
              <path d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z"/>
            </svg>
            <span>Edit</span>
          </button>
        }
        {edit &&
          <button className="btn-md rounded-full btn-neutral bg-black-800 font-semibold" onClick={handleSubmit(onSubmit)}>Update profile</button>
        }
      </div>      
      </form>
    </div>   
          
        
 
        
      

    
    </>
  )
}

export default User