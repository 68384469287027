import {useContext, createContext, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import AlertContext from '../../context/alerts/AlertContext'
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'
import UserPool from '../../UserPool'

const AuthContext = createContext()

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [token, setToken] = useState(null)
    const navigate = useNavigate()
    const setAlert = useContext(AlertContext)
    
    const loginAction = async(user) => {
        try {
            localStorage.setItem('token', user.token)
            if (!user.firstName) {
                //this is the first time they are signing in
                setTimeout(() => navigate('/profile'), 3000)
            } else {
              setTimeout(() => navigate('/dashboard'), 3000)
            }
            return
        } catch(err) {
            console.log(err)
            setAlert('Something went wrong and we are unable to process your credentials at this time. Please try in a few minutes','error')
        }
        
    }

    const signupAction = async(user) => {
        try{
            localStorage.setItem('token', user.token)
            setTimeout(() => navigate('/login'), 3000)
        } catch(err) {
            console.log(err)
            setAlert('Something went wrong and we are unable to process your credentials at this time. Please try in a few minutes','error')
        }
        return
    }

    const logoutAction = () => {
        setUser(null)
        setToken("")
        localStorage.removeItem('token')
        navigate('/login')
    }

    return <AuthContext.Provider value = {{token, user, loginAction, logoutAction, signupAction}}>
        {children}
    </AuthContext.Provider>
}

export default AuthProvider

export const useAuth = () => {
    return useContext(AuthContext)
}