const userReducer = (state, action) => {
    switch(action.type) {
        case 'SET_USER': {
            return {
            ...state, 
            user: action.payload
            }
        }
        case 'SET_USER_DETAILS': {
            return {
            ...state, 
            userDetails: action.payload,
            
            }
        }
        case 'SET_USER_EDIT': {
            return {
                ...state, 
                user: action.payload,
                userEditted: true
            }
        }
        case 'RESET_USER_EDIT': {
            return {
                ...state, 
                user: action.payload,
                userEditted: false
            }
        }
        case 'GET_USER': {
            console.log('In reducer', action.payload)
            return {
            ...state, 
            user: action.payload
            }
        }

        case 'USER_ERROR': {
            return {
                error: action.error
            }
        }

        default:
            return state 
        
    }
}

export default userReducer