import React from 'react'

function Verify() {
  return (
    <div>
        Thank you for successfully registering to PropptyFy!! 
        Click on this <a href="/">link</a> to login with your credentials!
    </div>
  )
}

export default Verify