import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import UserContext from '../../context/users/UserContext'


function Navbar({ title }) {
  const { user, dispatch } = useContext(UserContext)

  const handleClick = (e) => dispatch({type: 'SET_USER', payload: {}})

  return (
    <nav className ='navbar shadow-lg bg-neutral text-neutral-content'>
      <div className="container mx-auto">
        <div className="flex-none px-2 mx-2">
        <img alt="Propptyfy thumbnail" className="screen-shot" src="https://c.animaapp.com/0K95c8Xq/img/screen-shot-2023-10-20-at-3-28-1@2x.png" />
          <Link to='/' className='text-xl font-bold align-middle'>
            {title}
          </Link>
        </div>

        <div className='flex-1 px-2 mx-2'>
          <div className='flex justify-end'>
            <Link to="/" className='btn btn-ghost btn-sm rounded-btn'>
              Home
            </Link>
            <Link to="/about" className='btn btn-ghost btn-sm rounded-btn'>
              About
            </Link>
      
            {!user?.userId && (
              <Link to="/login" className='btn btn-ghost btn-sm rounded-btn'>
                Signin
              </Link>
            )}
            {user?.userId && (
              <Link to='/logout' onClick={handleClick}  className='btn btn-ghost btn-sm rounded-btn'>
                Logout
              </Link>
            )}
            <Link to="/support" className='btn btn-ghost btn-sm rounded-btn'>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

Navbar.defaultProps = {
  title: 'PROPPTYFY'
}

Navbar.propTypes = {
  title: PropTypes.string
}

export default Navbar