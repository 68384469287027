import React from 'react'
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup'
import Select from 'react-select'
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from 'react-hook-form'
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'
import UserPool from '../../UserPool'
import AlertContext from '../../context/alerts/AlertContext'
import UserContext from '../../context/users/UserContext'
import { useAuth } from '../../hooks/auth/AuthProvider'
import {useState, useContext} from 'react'
import LogoForLogin from '../assets/LogoForLogin.png'


function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const {setAlert} = useContext(AlertContext)
  const {setUser, dispatch} = useContext(UserContext)

  const schema = yup.object().shape({
    email: yup.string().email().required().label('Email'),
    password: yup.string().min(2).max(20).required().label('Password'),
    role: yup.string().label('Role').oneOf(['Property Manager','Tenant','Admin']),
    
  })

  const { register, control, handleSubmit, formState: { errors }} = useForm({resolver: yupResolver(schema), mode: "onSubmit"})

  const navigate = useNavigate()

  const auth = useAuth()

  
  const clearEntries = () => {
    setEmail('');
    setPassword('')
  }

  const onSubmit = (e) => {
    const loginUser = new CognitoUser({
        Username: email,
        Pool: UserPool
    })

    let authDetails = new AuthenticationDetails({
        Username: email,
        Password: password
    })
    let user = {}
    setAlert('', 'REMOVE_ALERT')
    loginUser.authenticateUser(authDetails, {
      onSuccess: (data) => {
          console.log(data)
          user.userId = data?.accessToken.payload?.sub
          user.token = data?.accessToken.jwtToken
          setAlert('Authentication Successful! ', 'success')
          if (user) {
            console.log('User ', user)
            dispatch({type: 'SET_USER', payload: user})
            
            //fetch user details
            setUser(user)
            auth.loginAction(user)
            
            if (!user.firstName) {
              //this is the first time they are signing in
              setTimeout(() => navigate('/profile'), 3000)
            }
            setTimeout(() => navigate('/dashboard'), 3000)  
          }
          
          
      },
      onFailure: (err) => {
          console.log(err)
          const errMessage = err.message || JSON.stringify(err)
          setAlert(errMessage, 'error') 
      },
      newPasswordRequired: (data) => {
          console.log("newPasswordRequired", data)
          return null
      }
    })
    
  }

  const handleEmail = (e) => setEmail(e.target.value)

  const handlePassword = (e) => setPassword(e.target.value)

  const options = [
    { value: 'manager', label: 'Property Manager' },
    { value: 'tenant', label: 'Tenant' },
    { value: 'Admin', label: 'Admin' },
];

 
  return (
    
      <div className='flex h-screen justify-center m-auto'>
        <form onSubmit={handleSubmit(onSubmit)}>
              <div className='overlap-6 grid flex flex-col justify-center items-center rounded form-control my-10 mx-10'>
              <div>
                  <h1 className="text-4xl font-sans text-center text-white font-bold">Log In</h1>
                </div>
                <div className='ml-10 mr-10 form-control'>
                  <input 
                    type="email"
                    value={email}
                    {...register("email", {
                      required: "Email is required"
                    })}
                    placeholder='Email'
                    onChange={handleEmail}
                    className='w-full pr-10 bg-gray-200 input input-md text-black py-4'/>
                  <p className="text-red-500 text-xs italic">{errors.email?.message}</p>
                </div>
                <div className="ml-10 mr-10 form-control">
                  <input 
                    type="password"
                    value={password}
                    {...register("password", {
                      required: "Password is required"
                    })}
                    placeholder='Password'
                    autoComplete='current-password'
                    onChange={handlePassword}
                    className='w-full pr-40 bg-gray-200 input input-md text-black py-4'/>
                    <p className="text-red-500 text-xs italic">{errors.password?.message}</p>
                </div>
                <div className='form-control w-full ml-10 mr-10'>
                  <div className = 'bg-gray-200 mr-10 max-w-xs text-gray-700 text-sm rounded-md'>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => (
                      <Select
                        className="react-drowndown text-gray-700"
                        defaultValue={options[0]}
                        {...field}
                        isClearable
                        isSearchable={false}
                        classNamePrefix="dropdown"
                        options={options}
                      />
                      )}
                    />
                  </div>
                  <p className="text-red-500 text-xs italic">{errors.role?.message || errors.role?.label.message}</p>
                </div>
                <div className="form-control ml-10 mr-10">
                  <button className="btn-sm rounded-full btn-neutral bg-black-800 font-semibold">Log In</button>
                </div>
                <div className='ml-20 mr-20 pl-10'>
                  <img alt="Login" src={LogoForLogin} />
                </div>
              </div>
          </form>  
          
      </div>
   
  )
}

export default Login


