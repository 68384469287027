import React from 'react'
import { useNavigate } from 'react-router-dom'
import AlertContext from '../../../context/alerts/AlertContext'
import UserContext from '../../../context/users/UserContext'
import DashboardHome from '../Dashboard/DashboardHome'
import {useState, useContext} from 'react'
import {
  TETabs,
  TETabsContent,
  TETabsItem,
  TETabsPane,
} from "tw-elements-react";
import User from '../User'



function UserDashboard() {
  const {user, setUser, userEditted, dispatch} = useContext(UserContext)
  const navigate = useNavigate()
  const [verticalActive, setVerticalActive] = useState("home");
  console.log('userEditted ', userEditted)

  const handleVerticalClick = (value) => {
    if (value === 'profile') {
      setUser(user)
      dispatch({type: 'RESET_USER_EDIT', payload: user})
    }
    setVerticalActive(value);
    if (value === verticalActive) {
      return;
    }
    
  };

  return (
    <div className="flex items-start">
    <TETabs vertical>
      <TETabsItem
        onClick={() => handleVerticalClick("home")}
        active={verticalActive === "home"}
      >
        Home
      </TETabsItem>
      <TETabsItem className='py-3'
        onClick={() => handleVerticalClick("profile")}
        active={verticalActive === "profile"}
      >
        Profile
      </TETabsItem>
      <TETabsItem
        onClick={() => handleVerticalClick("properties")}
        active={verticalActive === "properties"}
      >
        Properties
      </TETabsItem>
      <TETabsItem
        onClick={() => handleVerticalClick("tenants")}
        active={verticalActive === "tenants"}
      >
        Tenants
      </TETabsItem>
      <TETabsItem
        onClick={() => handleVerticalClick("payments")}
        active={verticalActive === "payments"}
      >
        Payments
      </TETabsItem>
      <TETabsItem
        onClick={() => handleVerticalClick("receivables")}
        active={verticalActive === "receivables"}
      >
        Receivables
      </TETabsItem>
      <TETabsItem
        onClick={() => handleVerticalClick("messages")}
        active={verticalActive === "messages"}
      >
        Messages
      </TETabsItem>
      <TETabsItem
        onClick={() => handleVerticalClick("access")}
        active={verticalActive === "access"}
        disabled
      >
        Grant/Revoke Access
      </TETabsItem>
    </TETabs>

    <TETabsContent className="h-screen w-3/4">
      <TETabsPane className="h-screen w-full" show={verticalActive === "home"}>{<DashboardHome/>}</TETabsPane>
      <TETabsPane className="h-screen w-full" show={verticalActive === "profile"}>{<User />}</TETabsPane>
      <TETabsPane show={verticalActive === "properties"}>Tab 3</TETabsPane>
      <TETabsPane show={verticalActive === "tenants"}>Tab 4 content</TETabsPane>
      <TETabsPane show={verticalActive === "payments"}>Tab 4 content</TETabsPane>
      <TETabsPane show={verticalActive === "receivables"}>Tab 4 content</TETabsPane>
      <TETabsPane show={verticalActive === "messages"}>Tab 4 content</TETabsPane>
      <TETabsPane show={verticalActive === "access"}>Tab 4 content</TETabsPane>
    </TETabsContent>
  </div>
  )
}

export default UserDashboard