import React from 'react'
import { useContext } from 'react'
import AlertContext from '../../context/alerts/AlertContext'

function Alert() {
  const {alert} = useContext(AlertContext)
  console.log('Alert------------', alert)
  return (
    alert !== null && (
        <div className="flex items-start space-x-2">
            {alert.type === 'error' && (
                <div className="z-30 w-full toast toast-top toast-start px-4 py-3" role="alert">
                  <div className="alert alert-error">
                    <strong>!! Error - {alert?.msg}</strong>
                  </div>
                </div>
              
            )}
            {alert !== '' && alert.type === 'success' && (
            <div className="z-30 w-full toast toast-top toast-start px-4 py-3" role="alert">
                <div className="alert alert-success">
                    <span>Success! - {alert?.msg}</span>
                </div>
            </div>
            
            )}
          </div>
    )
  )
}

export default Alert